<template>
  <div>
    <header class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2">
      <b-row>
        <b-col cols="3" class="d-flex align-items-center">
          <div style="cursor: pointer" @click="hideModals()">
            <feather-icon size="24" class="text-dark font-weight-bolder" icon="ArrowLeftIcon" />
            <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1">Kembali</span>
          </div>
        </b-col>
        <b-col cols="6" class="d-flex justify-content-center align-items-center flex-column">
          <h1 class="text-dark h6 font-weight-bolder mb-0 text-center">
            {{ details.name || "-" }}
          </h1>
          <small class="text-dark text-darken-5 fw-bold-500 size12">
            Telp : {{ details.phone_number || "-" }}
          </small>
        </b-col>
        <b-col cols="3" class="text-right">
          <!-- <b-button
            class="px-3 py-1 border-8 bg-white"
            size="sm"
          >
            Lihat Riwayat Pembayaran
          </b-button> -->
        </b-col>
      </b-row>
    </header>
    <b-container fluid id="utang" class="mt-5 pt-5" style="height: 100vh; overflow-y: scroll">
      <b-row class="mx-0 mt-1 justify-content-center">
        <b-col class="" cols="12">
          <b-table style="margin-bottom: 10rem" responsive="sm" :fields="fields" :items="items">
            <!-- fields: ['no_po', 'delivery_number_note', 'unpaid', 'due_date', 'no_invoice', 'factur_number_tax', 'amount'], -->
            <template #head(action)="">
              <div class="custom-control custom-checkbox">
                <input id="check-all" class="custom-control-input" type="checkbox" :value="checkAll"
                  :checked="selectAllValue && selectAllValue.length" @change="checkedAll(items)" />
                <label for="check-all" class="custom-control-label"> </label>
              </div>
            </template>
            <template #head(branch)="">
              <span>Cabang</span>
            </template>
            <template #head(no_po)="">
              <span>No. PO</span>
            </template>
            <template #head(delivery_numbers)="">
              <span>No. Surat Jalan</span>
            </template>
            <template #head(unpaid)="">
              <span>Nilai Utang</span>
            </template>
            <template #head(due_date)="">
              <span>Tgl Jatuh Tempo</span>
            </template>
            <template #head(no_invoice)="">
              <span>No. Invoice</span>
            </template>
            <template #head(factur_number_tax)="">
              <span>No. Faktur Pajak Pemasok</span>
            </template>
            <template #head(amount)="">
              <span>Bayar (Rp)</span>
            </template>
            <template #head(voucher)="">
              <span />
            </template>

            <!-- A custom formatted column -->
            <template #cell(action)="data">
              <b-form-checkbox v-model="formPayload.items[data.index].uuid" :value="data.item.uuid" />
            </template>

            <!-- A custom formatted column -->
            <template #cell(no_po)="data">
              <span class="text-blue" role="button" @click="
                detailPreOrder(
                  data.item.uuid_po,
                  data.item.is_stock_on_supplier
                )
                ">
                {{ data.item.no_po || "-" }}
              </span>
            </template>
            <template #cell(delivery_numbers)="data">
              <div v-if="data.item.delivery_numbers">
                <span v-if="data.item.delivery_numbers.length < 3">{{
                  data.item.delivery_numbers
                    .map((e) => e.delivery_number_note)
                    .slice(0, 3)
                    .join(", ")
                }}</span>
                <b-dropdown v-else :text="data.item.delivery_numbers
                  .map((e) => e.delivery_number_note)
                  .slice(0, 3)
                  .join(', ')
                  " variant="flat-secondary" class="delivery-numbers px-0">
                  <b-row class="mx-0 pt-1">
                    <b-col v-for="(item, index) in data.item.delivery_numbers" :key="index" class="">
                      <h6 class="text-dark">{{ item.delivery_number_note }}</h6>
                    </b-col>
                  </b-row>
                </b-dropdown>
              </div>
              <!-- <span style="white-space: pre-wrap">
                {{ data.item.delivery_numbers.map(e => e.delivery_number_note).join(', ') || '-' }}
              </span> -->
            </template>
            <template #cell(unpaid)="data">
              <span>
                {{ data.item.unpaid | formatAmount }}
              </span>
            </template>

            <!-- A custom formatted column -->
            <template #cell(voucher)="data">
              <b-dropdown offset="-230" :dropup="true" variant="outline-secondary bg-white p-1" class="btn-filter"
                :disabled="!formPayload.items[data.index].uuid">
                <template #button-content>
                  <b-img v-if="!formPayload.items[data.index].voucher_uuid.length"
                    :src="require(`@/assets/images/icons/coupon.svg`)" />
                  <b-img v-else :src="require(`@/assets/images/icons/coupon-active.svg`)" />
                </template>
                <b-dropdown-form class="p-0">
                  <b-form-group label-for="dropdown-form-voucher" class="mb-0 p-0" style="width: 280px" @submit.prevent>
                    <v-select v-model="vouchers[data.index]" placeholder="Pilih Voucher" label="voucher_code"
                      :options="options" multiple @search="onSearch" />
                  </b-form-group>
                </b-dropdown-form>
              </b-dropdown>
            </template>

            <!-- A custom formatted column -->
            <template #cell(no_invoice)="data">
              <b-form-group label="" label-for="v-no-invoice" class="custom__form--input mb-0">
                <b-form-input id="v-no-invoice" v-model="formPayload.items[data.index].invoice_number"
                  :disabled="!formPayload.items[data.index].uuid" type="text" placeholder="Invoice"
                  class="custom__input border-8 border-0" />
              </b-form-group>
            </template>
            <!-- A custom formatted column -->
            <template #cell(factur_number_tax)="data">
              <b-form-group label="" label-for="v-faktur" class="custom__form--input mb-0">
                <b-form-input id="v-faktur" v-model="formPayload.items[data.index].factur_number_tax"
                  :disabled="!formPayload.items[data.index].uuid" type="text" placeholder="No Faktur Pajak Supplier"
                  class="custom__input border-8" />
              </b-form-group>
            </template>
            <!-- A custom formatted column -->
            <template #cell(amount)="data">
              <!-- v-b-tooltip.hover.bottom="'Nilai pembayaran parsial terlalu kecil!'" -->
              <b-form-group label="" label-for="v-bayar" class="custom__form--input mb-0">
                <Cleave 
                  v-model="formPayload.items[data.index].amount"
                  :raw="false"
                  :options="optionNumber"
                  class="custom__input pl-2"
                />           
              </b-form-group>
            </template>
          </b-table>
        </b-col>
      </b-row>

      <div class="footer__purchase p-2" style="position: fixed">
        <b-row class="mx-0 align-items-center">
          <b-col lg="6">
            <h5 class="mb-0 text-dark fw-bold-600">
              Total Nilai Utang
              {{ (details.utang_total - details.utang_paid) | formatAmount }}
            </h5>
          </b-col>
          <b-col lg="6" class="text-right">
            <div class="d-flex justify-content-end">
              <b-button v-if="checkPermission('simpan pembayaran utang')"
                class="btn-primary py-2 size14 mt-0 border-8 mr-1" @click="savePayment">
                <span>Simpan</span>
              </b-button>
              <b-button v-if="checkPermission('bayar pembayaran utang')" class="btn-primary py-2 size14 mt-0 border-8"
                :disabled="formPayload.items.map((e) => e.uuid).every((id) => !id)
                  " @click="$bvModal.show('modal-payment')">
                <span>Bayar {{ (total + totalVoucher) | formatAmount }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>
    </b-container>
    <DetailSupplier v-if="result" :result="result" />
    <ModalPayment :total="total" :totalVoucher="totalVoucher" />
  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from "lodash";
import {
  BButton,
  BRow,
  BCol,
  BContainer,
  BTable,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BDropdown,
  BDropdownForm,
  BImg,
  VBTooltip,
} from "bootstrap-vue";
import vSelect from "vue-select";
import DetailSupplier from "@/components/Debt/Modal/Part/DetailSupplier.vue";
import ModalPayment from "@/components/Debt/Modal/ModalPayment.vue";
import {
  checkPermission,
  successNotification,
  formatRupiah,
  preformatFloat,
} from "@/auth/utils";

import Cleave from 'vue-cleave-component'

export default {
  components: {
    BButton,
    BRow,
    BCol,
    BContainer,
    BTable,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    DetailSupplier,
    BDropdown,
    BDropdownForm,
    BImg,
    vSelect,
    ModalPayment,
    Cleave
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      formatRupiah,
      preformatFloat,
    };
  },
  data() {
    return {
      voucher_coupon: "",
      fields: [
        "action",
        "branch",
        "no_po",
        "delivery_numbers",
        "unpaid",
        "due_date",
        "no_invoice",
        "factur_number_tax",
        "amount",
        "voucher",
      ],
      items: [],
      details: {},
      formPayload: {
        items: [],
        payment_method: "",
      },
      total: 0,
      userData: JSON.parse(localStorage.getItem("userData")),
      result: {},
      options: [],
      detail_voucher: {},
      vouchers: [],
      totalVoucher: 0,
      oldValueVoucher: [],
      voucherOptions: [],
      supplier_uuid: localStorage.getItem("customer_supplier_uuid"),
      selectAllValue: [],
      checkAll: false,
      optionNumber: {
        numeral: true,
        numeralThousandsGroupStyle: 'thousand',
        numeralDecimalScale: 2,
        numeralDecimalMark: ',',
        delimiter: '.'
      },
    };
  },
  watch: {
    voucher_coupon(value) {
      this.getDetailVoucher(value);
    },
    "$store.state.supplier.detailUtang": {
      handler(value) {
        this.details = value;
      },
      deep: true,
    },
    vouchers: {
      handler(value, oldValue) {
        let total = 0;
        const optionNewValue = [];

        setTimeout(() => {
          if (oldValue) {
            for (let i = 0; i < oldValue.length; i++) {
              const element = oldValue[i];
              if (element) {
                for (let k = 0; k < element.length; k++) {
                  const items = element[k];
                  this.oldValueVoucher.push((items && items.uuid) || "");
                }
              }
            }
          }
          for (let i = 0; i < value.length; i++) {
            const element = value[i];
            for (let j = 0; j < element.length; j++) {
              const items = element[j];
              optionNewValue.push(items.uuid);
              const indexOfObject = this.options.findIndex(
                (object) => object.uuid === items.uuid
              );
              if (indexOfObject >= 0) {
                this.options.splice(indexOfObject, 1);
              }
            }
          }
          const uniqueOldValue = [...new Set(this.oldValueVoucher)];
          const intersection = uniqueOldValue.filter(
            (x) => !optionNewValue.includes(x)
          );
          const eternalVoucher = this.voucherOptions.filter((el) =>
            intersection.includes(el?.uuid)
          );
          if (eternalVoucher.length > 0) {
            for (let index = 0; index < eternalVoucher.length; index++) {
              const element = eternalVoucher[index];
              if (
                this.options.some((el) => el.uuid === element?.uuid) === false
              ) {
                this.options.push(element);
              }
            }
          }
          for (let index = 0; index < value.length; index++) {
            const element = value[index];
            this.formPayload.items[index].voucher_uuid = [
              ...new Set(element.map((el) => el.uuid)),
            ];
            if (this.formPayload.items[index].voucher_uuid.length) {
              const totalAmount = element.reduce(
                (acc, el) => acc + el.amount,
                0
              );
              this.formPayload.items[index].amount = this.formPayload.items[index].unpaid - totalAmount
            } else {
              this.formPayload.items[index].amount = this.formPayload.items[index].unpaid
            }
            for (let j = 0; j < element.length; j++) {
              const item = element[j];

              total += item?.amount || 0;
            }
          }
          this.totalVoucher = total || 0;
        }, 300);
      },
      deep: true,
    },
    formPayload: {
      handler(value) {
        let total = 0;
        for (let index = 0; index < value.items.length; index++) {

          const element = value.items[index];
          element.is_checked = element.uuid ? 1 : 0;

          const formattedAmount = element.amount.length ? parseFloat(element?.amount?.replace(/\./g, "").replace(",", ".")) : '0';

          if (element.uuid) {
            total += formattedAmount;

            if (formattedAmount > element.unpaid) {
              setTimeout(() => {
                formattedAmount = element.unpaid;
              }, 300);
            }
          }
        }

        this.total = total;
        this.$store.commit("supplier/setTotal", total);
        this.$store.commit("supplier/setFormPayloadUtang", value);
      },
      deep: true,
    },
  },
  created() {
    if (!checkPermission("bayar pembayaran utang")) {
      this.removeArrayByValue("amount");
      this.removeArrayByValue("voucher");
    }
  },
  mounted() {
    this.getVoucher();
    this.details = this.$store.state.supplier.detailUtang;
    for (
      let index = 0;
      index < this.$store.state.supplier.detailUtang.items.length;
      index++
    ) {
      const element = this.$store.state.supplier.detailUtang.items[index];
      this.vouchers.push([]);
      this.formPayload.items.push({
        uuid:
          element.draft && element.draft.is_checked == 1 ? element.uuid : null,
        invoice_number:
          element.draft && element.draft.invoice_number !== "null"
            ? element.draft.invoice_number
            : "",
        factur_number_tax:
          element.draft && element.draft.factur_number_tax !== "null"
            ? element.draft.factur_number_tax
            : "",
        amount: formatRupiah(
          element.unpaid.toString().replace(".", ","),
          "Rp. "
        ),
        voucher_uuid: [],
        is_checked: element.draft && element.draft.is_checked,
        unpaid: element.unpaid,
      });
      this.items.push({
        is_stock_on_supplier: element.reference?.is_stock_on_supplier,
        uuid_po: element.reference?.uuid,
        index,
        uuid: element.uuid,
        no_po: element.reference?.po_so_id,
        branch: element.branch?.name,
        delivery_numbers: element.reference?.delivery_numbers || [],
        unpaid: element.unpaid,
        paid: element.paid,
        due_date: element.due_date,
      });
    }
  },
  methods: {
    hideModals() {
      // localStorage.removeItem("customer_supplier_uuid");
      this.$bvModal.hide("modal-debt");
    },
    checkedAll(items) {
      const filteredArray = this.getUniqueListBy(items, "uuid");
      if (!this.checkAll) {
        for (let index = 0; index < filteredArray.length; index++) {
          const element = filteredArray[index];
          this.formPayload.items[index].uuid = element.uuid;
        }
        this.selectAllValue = filteredArray;
        this.checkAll = true;
      } else {
        for (let index = 0; index < this.items.length; index++) {
          const element = this.items[index];
          this.formPayload.items[index].uuid = "";
        }
        this.selectAllValue = [];
        this.checkAll = false;
      }
    },
    getUniqueListBy(arr, key) {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    },
    getRupiah(index) {
      const formattedAmount = parseFloat(amount.replace(/\./g, "").replace(",", "."));

      const rupiah = document.getElementById(`rupiah-${index}`);
      rupiah.value = formattedAmount(rupiah.value);
      this.formPayload.items[index].amount = rupiah.value || "0";
    },
    async savePayment() {
      try {
        delete this.formPayload.payment_method;

        const form = this.preparePayload();

        const response = await this.$http.post(
          `/utang/${this.supplier_uuid}/add-payment-draft`,
          form
        );
        if (response.status === 200) {
          successNotification(
            this,
            "Success",
            "Utang berhasil disimpan ke draft"
          );
          this.$bvModal.hide("modal-debt");
        }
      } catch (error) {
        console.log(error);
      }
    },
    removeArrayByValue(x) {
      const index = this.fields.indexOf(x);
      if (index > -1) {
        this.fields.splice(index, 1);
      }
    },
    onSearch(search, loading) {
      if (search.length) {
        loading(true);
        this.searchVoucher(loading, search, this);
      } else {
        loading(true);
        setTimeout(() => {
          this.options = [];
          this.getVoucher();
          loading(false);
        }, 500);
      }
    },
    searchVoucher: _.debounce((loading, search, vm) => {
      vm.$store
        .dispatch("vouchers/getData", {
          uuid: "",
          params: {
            supplier_uuid: vm.supplier_uuid,
            search,
          },
        })
        .then((response) => {
          // eslint-disable-next-line no-param-reassign
          vm.options = [];
          vm.options = response.data.data.data.filter((el) => !el.is_used);
          // response.data.data.data.forEach(item => {
          //   if (!item.is_used) {
          //     vm.options.push({
          //       label: item.uuid,
          //       title: item.voucher_code,
          //     })
          //   }
          // })
          loading(false);
        });
    }, 300),
    getVoucher() {
      this.$store
        .dispatch("vouchers/getData", {
          uuid: "",
          params: {
            supplier_uuid: this.supplier_uuid,
          },
        })
        .then((result) => {
          this.options = result.data.data.data.filter((el) => !el.is_used);
          this.voucherOptions = result.data.data.data.filter(
            (el) => !el.is_used
          );
          // result.data.data.data.forEach(element => {
          //   if (!element.is_used) {
          //     this.options.push({
          //       label: element.uuid,
          //       title: element.voucher_code,
          //     })
          //   }
          // })
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getDetailVoucher(id) {
      this.$store
        .dispatch("vouchers/getData", {
          uuid: id,
          params: "",
        })
        .then((result) => {
          // console.log(result.data.data)
          this.detail_voucher = result.data.data;
          // this.$store.commit('supplier/setTotal', this.total - this.detail_voucher?.amount || 0)
        })
        .catch((err) => {
          console.log(err);
        });
    },
    detailPreOrder(uuid, isStockOnSupplier) {
      if (isStockOnSupplier) {
        this.$store
          .dispatch("stockWarehouse/getStockSupplier", {
            uuid,
            params: "",
          })
          .then((result) => {
            if (result.data.data) {
              this.result = result.data.data;
              this.$bvModal.show("modal-detail-supplier");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
      if (!isStockOnSupplier) {
        this.$store
          .dispatch("stockWarehouse/getData", {
            uuid,
            params: "",
          })
          .then((result) => {
            if (result.data.data) {
              this.result = result.data.data;
              this.$bvModal.show("modal-detail-supplier");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    preparePayload() {
      const form = new FormData();
      const filteredData = this.formPayload.items.filter((e) => e.uuid);
      // eslint-disable-next-line no-restricted-syntax, no-plusplus
      for (let index = 0; index < filteredData.length; index++) {
        const element = filteredData[index];
        // console.log(element, ' DEDOR');
        if (element.uuid) {
          // eslint-disable-next-line no-restricted-syntax
          for (const key in element) {
            // eslint-disable-next-line no-prototype-builtins
            if (element.hasOwnProperty(key) && element !== "") {
              const items = element[key];
              form.append(`items[${index}][${key}]`, items);
            }
          }
        }
      }
      return form;
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.text-blue {
  color: #004ad7;
}

#utang {
  .btn-outline-secondary.dropdown-toggle::after {
    background-image: unset;
  }

  .dropdown-toggle::after {
    width: 0;
    height: 0;
  }

  .delivery-numbers {
    .dropdown-toggle::after {
      width: 10px;
      height: 10px;
    }
  }

  .dropdown-menu,
  .b-dropdown-form {
    padding: 0;
  }

  #dropdown-form-voucher {
    &:focus {
      box-shadow: unset;
    }

    &::placeholder {
      font-size: 14px;
    }
  }
}
</style>
