<template>
  <b-modal
    id="modal-detail-supplier"
    hide-header
    hide-footer
    ok-only
    ok-title="Accept"
    centered
    size="lg"
    title="Large Modal"
    class="custom__modal"
  >
    <header class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2">
      <b-row>
        <b-col
          cols="3"
          class="d-flex align-items-center"
        >
          <div
            style="cursor: pointer;"
            @click="$bvModal.hide('modal-detail-supplier')"
          >
            <feather-icon
              size="24"
              class="text-dark font-weight-bolder"
              icon="ArrowLeftIcon"
            />
            <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1">Kembali</span>
          </div>
        </b-col>
        <b-col
          cols="6"
          class="d-flex justify-content-center align-items-center"
        >
          <h1 class="text-dark h6 font-weight-bolder mb-0 text-center">
            Produk Masuk Lokasi SKU
          </h1>
        </b-col>
      </b-row>
    </header>
    <b-container class="mt-5 pt-5">
      <b-row
        class="mx-0 pt-5 justify-content-center"
      >
        <b-col
          cols="12"
          md="6"
        >
          <b-row>
            <b-col
              cols="12"
              md="6"
              class="mb-2"
            >
              <h6 class="text-dark text-darken-5 fw-bold-600">
                Tanggal Masuk
              </h6>
              <h5 class="text-dark size18 fw-bold-600">
                <!-- 2 April 2022 -->
                {{ result.date || '-' }}
              </h5>
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="mb-2"
            >
              <h6 class="text-dark text-darken-5 fw-bold-600">
                Pemasok
              </h6>
              <h5 class="text-dark size18 fw-bold-600">
                {{ result.supplier && result.supplier.name || '-' }}
              </h5>
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="mb-2"
            >
              <h6 class="text-dark text-darken-5 fw-bold-600">
                No. Surat Jalan
              </h6>
              <h5 class="text-dark size18 fw-bold-600">
                {{ result.delivery_numbers && result.delivery_numbers.map(item => item.delivery_number_note).join(', ') || '-' }}
              </h5>
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="mb-2"
            >
              <h6 class="text-dark text-darken-5 fw-bold-600">
                No. PO / SO
              </h6>
              <h5 class="text-dark size18 fw-bold-600">
                {{ result.po_so_id || '-' }}
              </h5>
            </b-col>
            <b-col
              cols="12"
              md="6"
              class="mb-2"
            >
              <h6 class="text-dark text-darken-5 fw-bold-600">
                Lokasi SKU
              </h6>
              <h5 class="text-dark size18 fw-bold-600">
                {{ result.warehouse && result.warehouse.name || '-' }}
              </h5>
            </b-col>
            <b-col
              cols="12"
              md="6"
              class=""
            >
              <h6 class="text-dark text-darken-5 fw-bold-600">
                Pembayaran
              </h6>
              <h5 class="text-dark size18 fw-bold-600">
                {{ result.payment_method || 'Tempo' }}
              </h5>
            </b-col>
          </b-row>
          <hr>
          <b-row>
            <b-col cols="12">
              <h5 class="text-dark size16 fw-bold-700 mb-2">
                Daftar Produk
              </h5>
              <div v-for="(item, index) in result.items" :key="index">
                <h6 class="text-dark fw-bold-700">{{ item.product && item.product.name || '-' }}</h6>
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <h6 class="text-dark text-darken-5 fw-bold-600 size14 mb-0">
                      {{ item.price_after_discount || 0 | formatAmount }} - Jumlah : {{ item.qty || '-' }}
                    </h6>
                  </div>
                  <div class="total__product">
                    Total : {{ item.total || 0 | formatAmount }}
                  </div>
                </div>
                <hr>
              </div>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
  </b-modal>
</template>

<script>
import {
  BModal, BButton, BRow, BCol, BContainer, BTable, BFormGroup, BFormInput, BFormCheckbox, BDropdown, BDropdownForm, BImg, VBTooltip,
} from 'bootstrap-vue'

export default {
  components: {
    BDropdown,
    BDropdownForm,
    BButton,
    BRow,
    BCol,
    BContainer,
    BTable,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BImg,
    BModal,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    result: {
      type: Object,
    },
  },
  data() {
    return {
      voucher_coupon: '',
      items: [
        {
          action: '', no_po: 'MR/114/11/22', no_surat_jalan: '12343123', nilai_hutang: 'Rp 32.000.000', tgl_jatuh_tempo: '16 Mar 2022', no_invoice: '', no_faktur_pajak_supplier: '', bayar: 'Rp 0.00', voucher: null,
        },
        {
          action: '', no_po: 'MR/114/11/22', no_surat_jalan: '12343123', nilai_hutang: 'Rp 32.000.000', tgl_jatuh_tempo: '16 Mar 2022', no_invoice: '', no_faktur_pajak_supplier: '', bayar: 'Rp 0.00', voucher: 'asd',
        },
      ],
    }
  },
}
</script>

<style lang="scss">
.total__product {
  color: #287D3C;
  background: #DCF2E1;
  border-radius: 4px;
  padding:4px 8px;
  font-weight:700;
}
</style>
